@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import "../node_modules/react-toastify/dist/ReactToastify.css";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  //height: 100%;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  //height: -webkit-fill-available;
  overflow: auto;
}

*:focus {
  outline: 0;
}

body {
  -webkit-font-smoothing: antialiased !important;
  ::-webkit-scrollbar {
    display: none;
  }
}

body,
input,
button {
  color: #222;
  font-size: 12px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

a,
span {
  text-decoration: none;
  text-transform: none;
  justify-content: flex-start;
}
#root {
  height: 100%;
}
